/// <reference path="../suxesiv.ts" />
/// <reference path="../../../typings/index.d.ts" />


namespace Suxesiv {
    export class ResponsiveCollapse {

        protected $element: JQuery;
        protected $toggleElement:JQuery;
        protected screenSizeThreshold: Number;

        /**
         *
         * @param context
         */
        public constructor(element: JQuery|Node|String, toggleElement:JQuery|Node|String = element, screenSizeThreshold: Number) {
            this.$element = $(element);
            this.$toggleElement = $(toggleElement);
            this.screenSizeThreshold = screenSizeThreshold;

            this.$toggleElement.on('click touchend', this.eventClickToggle.bind(this));

            this.$element.collapse({
                'toggle':false
            });

            if (this.screenSizeThreshold >= $(window).width()) {
                this.$element.collapse('hide');
            }

            this.$element.on('shown.bs.collapse', this.eventShown.bind(this));
            this.$element.on('hidden.bs.collapse', this.eventHidden.bind(this));
        }

        public eventClickToggle(event:Event) {
            if (this.screenSizeThreshold >= $(window).width()) {
                event.preventDefault();
                this.$element.collapse('toggle');
            }
        }

        public eventShown() {
            this.$toggleElement.removeClass('collapsed');
        }

        public eventHidden() {
            this.$toggleElement.addClass('collapsed');
        }
    }
}
